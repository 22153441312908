import React, {useEffect} from "react";
import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Switcher from "../components/switcher";
import {useParams, Link} from "react-router-dom";

import { careerData } from "../data/careerData";

export default function CareerDetails(){
    useEffect(() => {
        document.documentElement.setAttribute("dir", "ltr");
        document.documentElement.classList.add('dark');
        document.documentElement.classList.remove('light');
      }, []);
    const params = useParams();
    const id = params.id;
    const data = careerData.find((career)=> career.id === parseInt(id));
    return(
       <>
        <Navbar/>

        <section className="relative md:pt-44 pt-36 bg-gradient-to-b from-amber-400/20 dark:from-amber-400/40 to-transparent">
            <div className="container relative">
                <div className="md:flex justify-center">
                    <div className="lg:w-2/3 md:w-4/5">
                        {/* <Link to="" className="bg-amber-400 text-white text-[12px] font-semibold px-2.5 py-0.5 rounded h-5">AI</Link> */}
                        <h5 className="md:text-4xl text-3xl font-bold md:tracking-normal tracking-normal md:leading-normal leading-normal mt-3">{data?.name}</h5>
                        <p className="text-slate-400 text-lg mt-3">{data?.place}</p>

                        {/* <div className="flex items-center mt-5">
                            <img src={data?.client ? data?.client : client1} className="h-12 w-12 rounded-full" alt=""/>

                            <div className="ms-2">
                                <h6><Link to="" className="font-medium hover:text-amber-400">{data?.author ? data?.author : "Calvin Carlo"}</Link><Link to="" className="ms-1 text-green-600 font-medium"><i className="mdi mdi-circle-medium"></i>Follow</Link></h6>
                                <span className="text-slate-400 text-sm">{data?.date ? data?.date : "August 25, 2023"} <span><i className="mdi mdi-circle-medium"></i>6 min read</span></span>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </section>

        <section className="relative md:pb-24 pb-16 pt-7">
            <div className="container relative">
                <div className="md:flex justify-center">
                    <div className="lg:w-2/3 md:w-4/5">

                    <h3 className="md:text-2xl text-2xl md:leading-normal leading-normal tracking-wider mt-10">
                        Job Description
                    </h3>
                        
                        
                        <ul className="text-slate-400 mt-4">{data?.jd?.map((point, index)=>
                            <li key={index}>{point}<br/></li>
                        )}</ul>
                        
                    <h3 className="md:text-2xl text-2xl md:leading-normal leading-normal tracking-wider mt-10">
                        Responsibilities
                    </h3>
                        
                        
                        <ul className="text-slate-400 mt-4">{data?.responsibilities?.map((point, index)=>
                            <li key={index}>&gt;&nbsp;{point}<br/></li>
                        )}</ul>

                    <h3 className="md:text-2xl text-2xl md:leading-normal leading-normal tracking-wider mt-10">
                        Qualifications
                    </h3>
                        
                        
                        <ul className="text-slate-400 mt-4">{data?.qualifications?.map((point, index)=>
                            <li key={index}>&gt;&nbsp;{point}<br/></li>
                        )}</ul>

                    <h3 className="md:text-2xl text-2xl md:leading-normal leading-normal tracking-wider mt-10">
                        Benefits
                    </h3>
                        
                        
                        <ul className="text-slate-400 mt-4">{data?.benefits?.map((point, index)=>
                            <li key={index}>&gt;&nbsp;{point}<br/></li>
                        )}</ul>

                        <div className="mt-10">
                         Eligible candidates mail your updated Resume / CV to 
                         <Link to="mailto:contact@example.com" className="hover:text-amber-400 font-bold"> info@demetrix.in</Link>
                        </div>

                        

                        
                    </div>
                </div>
            </div>
        </section>

        <section className="relative md:pb-24 pb-16 pt-7">
            <div className="container relative">
                 
            </div>

        </section>

        <Footer/>
        <Switcher/>
       </>

    )
}