import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import AboutImg from "../assets/images/Landing Image Design.mp4";
import image1 from "../assets/images/1.png";
import image2 from "../assets/images/2.png";
import image3 from "../assets/images/3.png";

import visionImg from "../assets/images/visionImg.png";
import objectiveImg from "../assets/images/objectiveImg.png";

import team1 from "../assets/images/team/01-Pramod_John-1.png";
import team2 from "../assets/images/team/03-Rosmin_Varghese-1.png";
import team3 from "../assets/images/team/04-Jinu-1.png";
import team4 from "../assets/images/team/02-Aravind_GC-1.png";
import team5 from "../assets/images/team/09-Joshy.jpeg";
import team6 from "../assets/images/client/01.jpg";
import team7 from "../assets/images/team/05-Vijay-1.png";
import team8 from "../assets/images/team/06-Sajith-1.png";
import team9 from "../assets/images/client/02.jpg";
import team10 from "../assets/images/team/07_Akbar_Shariff-1.png";
import team11 from "../assets/images/team/08-Suji-1.png";
import team12 from "../assets/images/team/10-Prashanth-1.png";


import ClientsTwo from "../components/clientTwo";
import Blogs from "../components/blogs";
import Footer from "../components/footer";
import Switcher from "../components/switcher";
import NavLight from "../components/navlight";

import { FiCheckCircle } from "../assets/icons/vander";

import ModalVideo from "react-modal-video";
import "../../node_modules/react-modal-video/css/modal-video.css";

export default function AboutUs() {
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("dark");
    document.documentElement.classList.remove("light");
  }, []);
  const [isOpen, setOpen] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);
  const teamData = [
    {
      image: team1,
      name: "Mr. Pramod John",
      title: "Founder | C.E.O",
      link: "https://www.linkedin.com/in/pramod-john-099a0327"
    },
    {
      image: team2,
      name: "Ms. Rosmin Varghese",
      title: "Co-Founder | C.O.O",
      link: "https://www.linkedin.com/in/rosmin-varghese-09a1071b6"
    },
    {
      image: team3,
      name: "Mr. Jinu Augustine",
      title: "Co-Founder | Solutions Architect",
      link: "https://www.linkedin.com/in/jinuaugustine"
    },
    {
      image: team4,
      name: "Mr. Aravind G C",
      title: "Director - Technical",
      link: "https://www.linkedin.com/in/aravindgc"
    },
    {
      image: team5,
      name: "Mr. Joshy Abraham",
      title: "Director - Marketing & Sales",
      link: "https://www.linkedin.com/in/dr-joshy-a-a139b330"
    },
    
    {
      image: team12,
      name: "Mr. Prasant John",
      title: "Director - Data",
      link: "https://www.linkedin.com/in/prasanth-john-61ab4715"
    },
    {
      image: team7,
      name: "Mr. Vijay Bhaskar",
      title: "Senior App Developer (Android/iOS/Web)",
      link: "https://www.linkedin.com/"
    },
    {
      image: team8,
      name: "Mr. Sajith Krishnan",
      title: "Engineer - IoT",
      link: "https://www.linkedin.com/in/sajithkrishnan"
    },
    // {
    //   // image: team9,
    //   name: "Ms. Anita Abraham",
    //   title: "Data Scientist",
    //   link: "https://www.linkedin.com/in/anitta-abraham-aa8740258"
    // },
    {
      image: team10,
      name: "Mr. Akbar Shariff",
      title: "Accounts",
      link: "https://www.linkedin.com/"
    },
    {
        image: team11,
        name: "Mr. Suji R",
        title: "Admin",
        link: "https://www.linkedin.com/in/suji-rajan-369210238"
      }
  ];
  return (
    <>
      <NavLight />
      <section className="relative md:py-24 py-32 bg-[url('../../assets/images/bg/bg-pages.jpg')] bg-no-repeat bg-cover">
        <div className="absolute inset-0 bg-gradient-to-t from-slate-900 to-slate-900/70"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 text-center mt-6">
            <div>
              <h5 className="md:text-4xl text-3xl md:leading-normal leading-normal tracking-wider font-semibold text-white mb-0">
                About Us
              </h5>
            </div>

            <ul className="tracking-[0.5px] mb-0 inline-block mt-5">
              <li className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white/50 hover:text-white">
                <Link to="/">Home</Link>
              </li>
              <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180">
                <i className="mdi mdi-chevron-right"></i>
              </li>
              <li
                className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white"
                aria-current="page"
              >
                About Us
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="relative">
        <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto scale-[2.0] origin-top"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <p className="text-slate-400">
            Our journey began with a
            strategic move, as the sustainable technology company SNIPL joined
            hands with us for technical advancement. This significant step has
            not only enriched our capabilities but has also set a new trajectory
            for the products initially developed by SNIPL.
          </p>
          <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-6 mt-4">
            {/* <div className="relative overflow-hidden after:content-[''] after:absolute after:inset-0 after:m-auto after:w-96 after:h-96 after:bg-gradient-to-tl after:to-amber-400 after:from-fuchsia-600 after:blur-[80px] after:rounded-full p-6 bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-slate-800 lg:me-6">
              <div className="relative overflow-hidden rounded-lg shadow-md dark:shadow-gray-800 z-1">
                <img src={AboutImg} alt="" />

                <div className="absolute bottom-2/4 translate-y-2/4 start-0 end-0 text-center">
                                <Link to="#!" onClick={() => setOpen(true)} className="lightbox lg:h-16 h-14 lg:w-16 w-14 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white dark:bg-slate-900 hover:bg-amber-400 dark:hover:bg-amber-400 text-amber-400 hover:text-white duration-500 ease-in-out mx-auto">
                                    <i className="mdi mdi-play inline-flex items-center justify-center text-2xl"></i>
                                </Link>
                            </div>
              </div>
              
            </div> */}
            {/* <ModalVideo
                        channel="youtube"
                        youtube={{ mute: 0, autoplay: 0 }}
                        isOpen={isOpen}
                        videoId="S_CGed6E610"
                        onClose={() => setOpen(false)} 
                    /> */}
                    <video src={AboutImg} className="rounded-lg" autoplay="true" muted />
            <div className="">
              {/* <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold"><span className="font-bold">Work smarter,</span> <br/> by using AI not  manually</h3> */}
              <p className="text-slate-400 max-w-xl">
                At Demetrix, we are more than just a technology firm; we are a
                dream realized. Our goal is to develop AI-based products that
                resonate with the needs of diverse stakeholders. We understand
                that technology should be accessible, user-friendly, and
                affordable. That's why our products are designed to bring ease
                of use and affordability to the forefront, ensuring that
                everyone, regardless of their background, can benefit from our
                innovations.
                <br />
                <br />
                As proud members of the Solidaridad family, our commitment goes
                beyond mere technological advancements. We stand as a digital
                partner, dedicated to serving the community with ardent fervor
                and vigor. Our mission is to tide over the digital divide,
                bringing the world closer through digital inclusivity.
                <br />
                <br />
                At Demetrix, we believe in growing together. Every step we take
                is a step towards a more connected and empowered society. Join
                us in our journey to make a difference, where technology is not
                just a tool, but a bridge to a brighter, more inclusive future.
              </p>

              {/* <ul className="list-none text-slate-400 mt-4">
                <li className="mb-2 flex items-center">
                  <FiCheckCircle className="text-amber-400 h-5 w-5 me-2" />{" "}
                  Bridging people and technology
                </li>
                <li className="mb-2 flex items-center">
                  <FiCheckCircle className="text-amber-400 h-5 w-5 me-2" />{" "}
                  Innovate new ideas and convert it as products
                </li>
                <li className="mb-2 flex items-center">
                  <FiCheckCircle className="text-amber-400 h-5 w-5 me-2" />{" "}
                  Finding and motivating stakeholders towards technology
                </li>
              </ul> */}

              {/* <div className="mt-4">
                            <Link to="" className="hover:text-amber-400 font-medium duration-500">Read More <i className="mdi mdi-chevron-right text-[20px] align-middle"></i></Link>
                        </div> */}
            </div>
          </div>
        </div>

        <div className="container relative md:mt-24 mt-16">
          <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-center">
            Our Mission
          </h3>
          <div className="">
              <img
                src={objectiveImg}
                className="rounded"
                alt=""
              />
            </div>
          {/* <div
            className="grid md:grid-cols-2 grid-cols-1 items-center gap-6"
          >
            <div className="">
              <p className="text-slate-400">
                Demetrix has developed a trusted relationship with stakeholder
                companies and their associations.
                <br />
                <br />
                The Demetrix team has a long track record in organizing capacity
                building, project management, gender sensitisation and
                monitoring and evaluation, focused on producer support,
                sustainable supply chains and market driven development. Its
                added value is that it can bring the public and private
                stakeholders together around a meaningful and realistic agenda
                for change.
              </p>
            </div>
            <div className="">
              <img
                src={objectiveImg}
                className="rounded-lg"
                alt=""
              />
            </div>
          </div> */}
        </div>

        <div className="container relative md:mt-24 mt-16">
          <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-center">
            Our Vision
          </h3>
          {/* <div
            className="grid md:grid-cols-2 grid-cols-1 items-center gap-6"
          > */}
            <div className="">
              <img
                src={visionImg}
                className="rounded"
                alt=""
              />
            </div>

            {/* <div className="">
              <h3 className="mb-4 md:text-1xl md:leading-normal text-1.5xl leading-normal font-bold">
                How To Utilize The Next Generation Technology?
              </h3>
              <h3 className="md:text-1xl md:leading-normal text-1.5xl leading-normal">
                Internet Of Things:
              </h3>
              <p className="text-slate-400">
                Smallholders produce 40 percent of the world's palm oil.
                Inefficient and economically-disadvantaged smallholder practices
                result.
              </p>
              <h3 className="mt-4 md:text-1xl md:leading-normal text-1.5xl leading-normal">
                Smart Farming:
              </h3>
              <p className="text-slate-400">
                Conventional farming demands extensive resources, including
                water and capital. On the other hand, smart farming adopts
                technological.
              </p>
              <h3 className="mt-4 md:text-1xl md:leading-normal text-1.5xl leading-normal">
                Precision Farming:
              </h3>
              <p className="text-slate-400">
                Precision farming, or Site-Specific Crop Management (SSCM),
                adopts satellite or drone imagery to optimize strategies.
              </p>
              <h3 className="mt-4 md:text-1xl md:leading-normal text-1.5xl leading-normal">
                Robotics & Automation:
              </h3>
              <p className="text-slate-400">
                Farmers suffer yearly due to labor shortages and human-induced
                errors. Harvesting and irrigation continue to remain a
                challenge.
              </p>
              <h3 className="mt-4 md:text-1xl md:leading-normal text-1.5xl leading-normal">
                AI & Big Data:
              </h3>
              <p className="text-slate-400">
                Modern IoT sensors gather a lot of data about soil and weather
                conditions, which are of no use unless analyzed. Startups are
                developing.
              </p>
              <h3 className="mt-4 md:text-1xl md:leading-normal text-1.5xl leading-normal">
                Drones & Satellites:
              </h3>
              <p className="text-slate-400">
                Crop management is labor-intensive and consumes time and
                capital. Drones and satellites, on the other hand, are efficient
                tools in-field.
              </p>
            </div> */}
          {/* </div> */}
        </div>

        <div className="container relative md:mt-24 mt-16">
          <div className="grid grid-cols-1 pb-6 text-center">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              The Team
            </h3>

            <p className="text-slate-400 max-w-xl mx-auto">
              The core pillars of Demetrix Infotech
            </p>
          </div>

          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
            {teamData.map((item, index) => {
              return (
                <div
                  className="overflow-hidden relative w-full mx-auto bg-white dark:bg-slate-900 shadow hover:shadow-md dark:shadow-slate-800 rounded-md flex items-center duration-500"
                  key={index}
                >
                  <img
                    src={item.image}
                    alt=""
                    className="absolute -start-10 w-40 h-40 rounded-full shadow-lg"
                  />
                  <div className="min-w-0 py-10 ps-36 pe-6">
                    <Link
                      to={item.link}
                      className="text-lg font-medium hover:text-amber-400"
                      target="_blank"
                    >
                      {item.name}
                    </Link>
                    <p className="text-slate-400">{item.title}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {/* <ClientsTwo />
        <Blogs /> */}
      </section>

      <Footer />
      <Switcher />
    </>
  );
}
