import React,{useEffect, useState} from "react";
import { Link, redirect } from "react-router-dom";

import contactImg from "../assets/images/contact.svg"

import Navbar from "../components/navbar";
import Footer from "../components/footer";
import Switcher from "../components/switcher";

import {FiHexagon, FiPhone,FiMail, FiMapPin} from "../assets/icons/vander"

export default function Contact(){
    useEffect(() => {
        document.documentElement.setAttribute("dir", "ltr");
        document.documentElement.classList.add('dark');
        document.documentElement.classList.remove('light');
      }, []);

    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [ques, setQues] = useState();
    const [comments, setComments] = useState();

    var nameError = 0;
    var emailError = 0;
    var quesError = 0;

    // console.log(name)

    const handleSubmit = () => {
        if(name==""){
            nameError = 1;
        }else{
            nameError = 0;
        }
        if(email==""){
            emailError = 1;
        }else{
            emailError = 0;
        }
        if(ques==""){
            quesError = 1;
        }else{
            quesError = 0; 
        }

        if (!nameError && !emailError && !quesError){
            const myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            const raw = JSON.stringify({
                "name": name,
                "email": email,
                "question": ques,
                "comments": comments
            })

            const requestOptions = {
                method: "POST",
                headers: myHeaders,
                body: raw,
                redirect: "follow",
            }

            fetch("https://website-backend.demetrix.in/contact-us", requestOptions)
            .then((response)=>response.text())
            .then((result)=>{
                console.log(result)
                let resultArray = JSON.parse(result)
                if(resultArray.status === 201){
                    alert(resultArray.message)
                }else{
                    alert("Please fill in the fields")
                }
            })
            .catch((error)=>console.error(error))
        }
    }

    return(
        <>
        <Navbar/>
        <div className="container-fluid relative mt-20">
            
        </div>
        <section className="relative lg:py-24 py-16">
            <div className="container">
                <div className="grid md:grid-cols-12 grid-cols-1 items-center gap-[30px]">
                    <div className="lg:col-span-7 md:col-span-6">
                        <img src={contactImg} alt=""/>
                    </div>

                    <div className="lg:col-span-5 md:col-span-6">
                        <div className="lg:ms-5">
                            <div className="bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-700 p-6">
                                <h3 className="mb-6 text-2xl leading-normal font-semibold">Get in touch !</h3>

                                <form>
                                    <p className="mb-0" id="error-msg"></p>
                                    <div id="simple-msg"></div>
                                    <div className="grid lg:grid-cols-12 lg:gap-6">
                                        <div className="lg:col-span-6 mb-5">
                                            <label htmlFor="name" className="font-semibold">Your Name:</label>
                                            <input required={true}  name="name" id="name" type="text" className="form-control w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2" placeholder="Name:" onChange={(e)=>{setName(e.target.value)}}/>
                                            {name===""? (<div style={{color:"red"}}>Required!</div>):(<div></div>)}
                                        </div>
        
                                        <div className="lg:col-span-6 mb-5">
                                            <label htmlFor="email" className="font-semibold">Your Email:</label>
                                            <input required  name="email" id="email" type="email" className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2" placeholder="Email:" onChange={(e)=>{setEmail(e.target.value)}}/>
                                            {email===""?(<div style={{color:"red"}}>Required!</div>):(<div></div>)}
                                        </div>
                                    </div>
    
                                    <div className="grid grid-cols-1">
                                        <div className="mb-5">
                                            <label htmlFor="subject" className="font-semibold">Your Question:</label>
                                            <input required  name="subject" id="subject" className="form-input w-full py-2 px-3 h-10 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2" placeholder="Subject:" onChange={(e)=>{setQues(e.target.value)}}/>
                                            {ques===""?(<div style={{color:"red"}}>Required!</div>):(<div></div>)}
                                        </div>
    
                                        <div className="mb-5">
                                            <label htmlFor="comments" className="font-semibold">Your Comment:</label>
                                            <textarea required name="comments" id="comments" className="form-input w-full py-2 px-3 bg-transparent dark:bg-slate-900 dark:text-slate-200 rounded outline-none border border-gray-200 focus:border-amber-400 dark:border-gray-800 dark:focus:border-amber-400 focus:ring-0 mt-2 h-40" placeholder="Message:" onChange={(e)=>{setComments(e.target.value)}}></textarea>
                                        </div>
                                    </div>
                                    {/* <button type="submit" id="submit" name="send" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-amber-400 hover:bg-amber-500 border-amber-400 hover:border-amber-500 text-white rounded-md">Send Message</button> */}
                                </form>
                                <button className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-amber-400 hover:bg-amber-500 border-amber-400 hover:border-amber-500 text-white rounded-md" onClick={handleSubmit}>Send Message</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <div className="container lg:mt-24 mt-16">
                <div className="grid grid-cols-1 lg:grid-cols-2 md:grid-cols-2 gap-[30px]">
                    <div className="text-center px-6">
                        <div className="relative overflow-hidden text-transparent -m-3">
                            <FiHexagon className="h-24 w-24 fill-amber-400/5 group-hover:fill-white/10 mx-auto"/>
                            <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-amber-400 rounded-xl group-hover:text-white duration-500 text-2xl flex align-middle justify-center items-center">
                                <FiPhone/>
                            </div>
                        </div>

                        <div className="content mt-7">
                            <h5 className="title h5 text-lg font-semibold">Phone</h5>
                            <p className="text-slate-400 mt-3">Dial to reach us on</p>
                            
                            <div className="mt-5">
                                <Link to="tel:+152534-468-854" className="hover:text-amber-400">+91 81475 36636</Link>
                            </div>
                        </div>
                    </div>

                    <div className="text-center px-6">
                        <div className="relative overflow-hidden text-transparent -m-3">
                            <FiHexagon className="h-24 w-24 fill-amber-400/5 group-hover:fill-white/10 mx-auto"/>
                            <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-amber-400 rounded-xl group-hover:text-white duration-500 text-2xl flex align-middle justify-center items-center">
                               <FiMail/>
                            </div>
                        </div>

                        <div className="content mt-7">
                            <h5 className="title h5 text-lg font-semibold">Email</h5>
                            <p className="text-slate-400 mt-3">Reach out to us @</p>
                            
                            <div className="mt-5">
                                <Link to="mailto:contact@example.com" className="hover:text-amber-400">info@demetrix.in</Link>
                            </div>
                        </div>
                    </div>

                    {/* <div className="text-center px-6">
                        <div className="relative overflow-hidden text-transparent -m-3">
                            <FiHexagon className="h-24 w-24 fill-amber-400/5 group-hover:fill-white/10 mx-auto"/>
                            <div className="absolute top-2/4 -translate-y-2/4 start-0 end-0 mx-auto text-amber-400 rounded-xl group-hover:text-white duration-500 text-2xl flex align-middle justify-center items-center">
                                <FiMapPin/>
                            </div>
                        </div>

                        <div className="content mt-7">
                            <h5 className="title h5 text-lg font-semibold">Location</h5>
                            <p className="text-slate-400 mt-3">#401, 4th Floor, HVS Court <br/> Cunningham Road, Bangalore, Karnataka.<br/> Pin-code: 560052</p>
                            
                            <div className="mt-5">
                                <Link to="#"
                                data-type="iframe" className="video-play-icon read-more lightbox hover:text-amber-400">View on Google map</Link>
                            </div>
                        </div>
                    </div> */}
                </div>
            </div>

            <div className="grid grid-cols-1">
            <h3 className="mb-6 text-2xl leading-normal font-semibold text-center  mt-20">Find us @</h3>
                <div className="w-full leading-[0] border-0">
                    
                    
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1943.8992976615414!2d77.59632969863979!3d12.984729993093753!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae16660f9fbdf7%3A0x105c093a15d60fbc!2s402%2C4th%20floor%2C%20HVS%20Court%2C%20Indian%20express%20circle%20signal%2C%20Cunningham%20Rd%2C%20opposite%20DBS%20house%2C%20Vasanth%20Nagar%2C%20Bengaluru%2C%20Karnataka%20560052!5e0!3m2!1sen!2sin!4v1719570173225!5m2!1sen!2sin" className=" container" style={{width:"50%",border:"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d39206.002432144705!2d-95.4973981212445!3d29.709510002925988!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8640c16de81f3ca5%3A0xf43e0b60ae539ac9!2sGerald+D.+Hines+Waterwall+Park!5e0!3m2!1sen!2sin!4v1566305861440!5m2!1sen!2sin" title="my-map" style={{border:"0"}} className="w-full h-[500px]" allowFullScreen></iframe> */}
                    <div className="content mt-7 text-center">
                            <h5 className="title h5 text-lg font-semibold">Location</h5>
                            <p className="text-slate-400 mt-3">#401, 4th Floor, <br/> HVS Court, Cunningham Road <br/> Bangalore, Karnataka - 560052</p>
                            
                        </div>
                </div>
            </div>
        </section>
        <Footer/>
        <Switcher/>
        </>
    )
}