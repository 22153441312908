import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

import Footer from "../components/footer";
import Switcher from "../components/switcher";
import NavLight from "../components/navlight";

import product1Img from "../assets/images/products/software/product1.jpg";
import product2Img from "../assets/images/products/software/i2i.png";
import product3Img from "../assets/images/products/software/product3.png";

import ballotronixImg from "../assets/images/products/ai-hardware/ballotonix.png";
import probeImg from "../assets/images/products/ai-hardware/soil-probe.png";
import metImg from "../assets/images/products/ai-hardware/agri-met.png";

import xploreEaseImg from "../assets/images/products/ai-software/xploreEase1.png";
import classIQ from "../assets/images/products/ai-software/classIQ1.png";
import assureImg from "../assets/images/products/ai-software/Soli-Assure.jpg";
import farmImg from "../assets/images/products/ai-software/Farmwise.png";
import traceImg from "../assets/images/products/ai-software/agri-trail.png";
import eLearnImg from "../assets/images/products/ai-software/e-learn.png";
import trinityImg from "../assets/images/products/ai-software/trinity.png";
import agribotImg from "../assets/images/products/ai-software/agribot.png";
import farmLogImg from "../assets/images/products/ai-software/farm-log1.png";
import carbonImg from "../assets/images/products/ai-software/carbon.png";
import crop4seeImg from "../assets/images/products/ai-software/crop4see.png";
import dLenzImg from "../assets/images/products/ai-software/d-lenz.png";
import smartBinImg from "../assets/images/products/ai-software/smart-bin.png";

import QR1 from "../assets/images/products/ai-software/constitution-QR.jpeg";
import QR2 from "../assets/images/products/ai-software/IPC-QR.jpeg";
import QR3 from "../assets/images/products/ai-software/CrPC-QR.jpeg";
import QR4 from "../assets/images/products/ai-software/IEA-QR.jpeg";

export default function Products() {
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("dark");
    document.documentElement.classList.remove("light");
  }, []);

  const productType = localStorage.getItem("productType");
  const [products, setProducts] = useState();
  const [title, setTitle] = useState();

  // const softwareSolutions = [
  //   {
  //     id: 1,
  //     image: product1Img,
  //     title: "Traceability Solutions for Tea Industry",
  //     desc: "Technology-powered traceability solution SoliTrace transforms agribusinesses into purpose-driven brands, delivers better value for farmers and empowers consumers. A more convenient market and finance system, as well as economic rewards for farmers.",
  //     link: "/product-details/1",
  //   },
  //   {
  //     id: 2,
  //     image: product2Img,
  //     title: "Empowerment of Women in Dairy",
  //     desc: "Demetrix's digital model for direct earnings to woman farmers selling milk. The significance of Demetrix's digital model for empowering woman farmers and promoting gender equality in the agricultural sector.",
  //     link: "/product-details/2",
  //   },
  //   {
  //     id: 3,
  //     image: product3Img,
  //     title: "Crop Predictability in Fruits and Vegetables",
  //     desc: "In the world of agriculture, predictability plays a crucial role in ensuring sustainable food production. Farmers and agricultural researchers have long been striving to improve crop predictability, especially when it comes to fruits and vegetables. The ability to forecast yields accurately can lead to increased efficiency, reduced waste, and better decision-making for farmers.",
  //     link: "/product-details/3",
  //   },
  // ];

  const aiSoftware = [
    // {
    //   id: 1,
    //   image: product1Img,
    //   title: "Traceability Solutions for Tea Industry",
    //   desc: "Technology-powered traceability solution SoliTrace transforms agribusinesses into purpose-driven brands, delivers better value for farmers and empowers consumers. A more convenient market and finance system, as well as economic rewards for farmers.",
    //   link: "/product-details/1",
    // },
    {
      id: 8,
      image: traceImg,
      title: "Agri-Trail",
      tagline: "Transparency and Sustainability in Your Supply Chain",
      desc: "Agri-Trail is a technology-powered traceability solution that transforms agribusinesses into purpose-driven brands, delivers better value for farmers, and empowers consumers through supply chain transparency.",
      link:"/product-details/8"
    },
    // {
    //   id: 10,
    //   image: trinityImg,
    //   title: "Trinitea",
    //   tagline: "",
    //   desc: "Trinitea is a self-assurance application that brings a fresh perspective to the conventional sustainability standards in the tea supply chain.",
    //   link:"/product-details/10"
    // },
    // {
    //   id: 2,
    //   image: product2Img,
    //   title: "i2i",
    //   desc: "Demetrix's digital model for direct earnings to woman farmers selling milk. The significance of Demetrix's digital model for empowering woman farmers and promoting gender equality in the agricultural sector.",
    //   link: "/product-details/2",
    // },
    // {
    //   id: 3,
    //   image: product3Img,
    //   title: "Crop Predictability in Fruits and Vegetables",
    //   desc: "In the world of agriculture, predictability plays a crucial role in ensuring sustainable food production. Farmers and agricultural researchers have long been striving to improve crop predictability, especially when it comes to fruits and vegetables. The ability to forecast yields accurately can lead to increased efficiency, reduced waste, and better decision-making for farmers.",
    //   link: "/product-details/3",
    // },
    {
      id: 4,
      image: xploreEaseImg,
      title: "XplorEase",
      tagline:"Instant Chatbot Generator",
      desc: "XplorEase revolutionizes information access using QR code technology, offering an intuitive and efficient way to explore and engage with various locations.",
      link:"/product-details/4"
    },
    {
      id: 5,
      image: classIQ,
      title: "ClassIQ",
      tagline:"Learn on the Go",
      desc: "ClassIQ is a comprehensive solution designed to transform traditional classrooms into dynamic learning hubs.",
      link:"/product-details/5"
    },
    // {
    //   id: 6,
    //   image: assureImg,
    //   title: "Soli-Assure",
    //   desc: "It is a cost effective tool for assessments/certifications which monitor the continual improvement of farmers.",
    //   link:"/product-details/6"
    // },
    {
        id: 7,
        image: farmImg,
        title: "Farm-Wise",
        tagline:"Farmers tool for self-assessment and certification",
        desc: "Farm-Wise is a robust self-assurance tool designed to streamline the digitization of standards and certifications in agriculture. It helps stakeholders assess sustainability levels, identify risks, plan strategically, implement best practices, and ensure continual improvement.",
        link:"/product-details/7"
      },
      
      {
        id: 9,
        image: eLearnImg,
        title: "E-Learning",
        tagline:"Learn on the Go",
        desc: "E-Learning facilitates the creation and management of educational courses and assessments tailored for farmers and agricultural stakeholders.",
        link:"/product-details/9"
      },
      {
        id: 14,
        image: agribotImg,
        title: "Agri-Bot",
        tagline:"Farmer’s expert Guide",
        desc: "Agri-Bot is a farming companion offering personalized, real-time assistance to help farmers optimize crop production, enhance decision-making, and achieve greater success.",
        link:"/product-details/14"
      },
      {
        id: 15,
        image: farmLogImg,
        title: "Farm-Log",
        tagline:"Farmer’s Daily Diary",
        desc: "Farm-Log is an advanced farm management tool designed to streamline various aspects of farm operations.",
        link:"/product-details/15"
      },
      {
        id: 16,
        image: carbonImg,
        title: "Carbon Sequester",
        tagline:"Farmer’s guide to carbon",
        desc: "Carbon Sequester is an advanced tool that provides farmers with detailed insights into carbon sequestration achieved through regenerative agriculture practices.",
        link:"/product-details/16"
      },
      {
        id: 17,
        image: crop4seeImg,
        title: "Crop4See",
        tagline:"Yield prediction tool",
        desc: "Crop4See is a sophisticated tool designed to forecast crop production using advanced technologies such as satellite and drone imagery, combined with historical data.",
        link:"/product-details/17"
      },
      {
        id: 18,
        image: dLenzImg,
        title: "D-Lenz",
        tagline:"Leaf disease detector",
        desc: "D-Lenz is an advanced AI-based tool designed to assist farmers in identifying leaf diseases and pests using artificial intelligence and extensive agricultural disease data.",
        link:"/product-details/18"
      },
     
      
  ];

  const aiHardware = [
    {
      id: 11,
      image: ballotronixImg,
      title: "Ballotronix",
      tagline:"Tea Leaf Reader",
      desc: "Ballotronix automates the grading of tea leaves using advanced computer vision and deep learning technologies.",
      link: "/product-details/11",
    },
    {
      id: 12,
      image: probeImg,
      title: "Soil-Probe",
      tagline:"Instant Soil Analyser",
      desc: "Soil-Probe provides instant soil analysis with ease, offering a portable device that determines 8 soil parameters (N, P, K, salinity, soil moisture, soil temperature, electrical conductivity and pH) and provides land contour mapping.",
      link:"/product-details/12"
    },
    {
      id: 13,
      image: metImg,
      title: "Agri-Met",
      tagline:"Hyperlocal weather station",
      desc: "Agri-Met is a hyperlocal weather station leveraging AI and high-precision sensors for weather updates, soil and plant monitoring, and pest and animal detection.",
      link:"/product-details/13"
    },
    {
      id: 19,
      image: smartBinImg,
      title: "Smart Bins",
      tagline:"Smart Waste Management",
      desc: "Smart Bins leverage IoT technology for real-time waste management notifications and enhanced efficiency in garbage collection processes.",
      link:"/product-details/19"
    },
  ];

  const QR = [{
    image: QR1,
    title: "The Constitution of India",
    url: "https://bot.xplorease.com/?session=0fc48507f37e44d58e4ce9213428b031"
  },{
    image: QR2,
    title: "Bharatiya Nagarik Suraksha Sanhita - 2023 & IPC",
    url: "https://bot.xplorease.com/?session=78eb5b887d53424a9a2158c313692fea"
  },
  {
    image: QR3,
    title: "Bharatiya Nagarik Suraksha Sanhita & CrPC",
    url: "https://bot.xplorease.com/?session=58909e54ef61467db8c94257aa857113"
  },
  {
    image: QR4,
    title: "Bharatiya Sakshya Adhiniyam & IEA",
    url: "https://bot.xplorease.com/?session=e184a90be995409eaca73e40b45cbb45"
  }
  ];

  useEffect(() => {
    // if (productType === "1") {
    //   setProducts(softwareSolutions);
    //   setTitle("Software Solutions");
    // }
    if (productType === "2") {
      setProducts(aiSoftware);
      setTitle("Software Products");
    } else if (productType === "3") {
      setProducts(aiHardware);
      setTitle("Hardware Products");
    }
  });

  return (
    <>
      <NavLight />
      <section className="relative md:py-24 py-32 bg-[url('../../assets/images/bg/bg-pages.jpg')] bg-no-repeat bg-bottom bg-cover">
        <div className="absolute inset-0 bg-gradient-to-t from-slate-900 to-slate-900/70"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 text-center mt-6">
            <div>
              <h5 className="md:text-4xl text-3xl md:leading-normal leading-normal tracking-wider font-semibold text-white mb-0">
                {title}
              </h5>
            </div>

            <ul className="tracking-[0.5px] mb-0 inline-block mt-5">
              <li className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white/50 hover:text-white">
                <Link to="/">Home</Link>
              </li>
              <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180">
                <i className="mdi mdi-chevron-right"></i>
              </li>
              <li
                className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white"
                aria-current="page"
              >
                {title}
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="relative">
        <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto scale-[2.0] origin-top"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
            {products?.map((item, index) => {
              return (
                <div
                  className="relative bg-white dark:bg-slate-900 p-4 rounded-md shadow dark:shadow-gray-700"
                  key={index}
                >
                  <img
                    src={item.image}
                    className="rounded-md shadow dark:shadow-gray-700"
                    alt=""
                  />
                  <div className="pt-4">
                    <div className="flex justify-between items-center">
                      {/* <div className="space-x-1">
                                            <Link to="" className="bg-amber-400/10 text-amber-500 dark:text-amber-400 text-[12px] font-semibold px-2.5 py-0.5 rounded h-5">Software</Link>
                                            <Link to="" className="bg-amber-400/10 text-amber-500 dark:text-amber-400 text-[12px] font-semibold px-2.5 py-0.5 rounded h-5">Farmer Development</Link>
                                        </div> */}

                      {/* <span className="flex items-center"><FiClock className="h-4 w-4"/><span className="ms-1 text-slate-400">5 min read</span></span> */}
                    </div>

                    <div className="mt-5">
                      <h3 className="text-lg font-semibold hover:text-amber-400">
                        {item.title}
                      </h3>
                      <p className="" style={{fontSize:"14px"}}>
                        {item.tagline}
                      </p>
                      {/* <Link to={`/blog-detail/${item.id}`} className="text-lg font-semibold hover:text-amber-400">{item.title}</Link> */}
                    </div>

                    <div className="mt-5 flex justify-between items-center" style={{marginBottom:"10%"}}>
                      <span className="flex items-center">
                        {/* <img src={item.client} className="h-7 w-7 rounded-full" alt=""/> */}
                        <p className="ms-1 text-slate-400 hover:text-amber-400">
                          {item.desc}
                        </p>
                        
                      </span>
                     
                      {/* <span className="flex items-center"><FiCalendar className="h-4 w-4"/><span className="ms-1 text-slate-400">{item.date}</span></span> */}
                    </div>

                   {
                    item.id === 4 &&
                    <div className="mt-5">
                      <Link
                        to="https://xplorease.com/"
                        target="_blank"
                        className="hover:text-amber-400 font-medium duration-500"
                      >
                        Learn More about XploreEase?
                      </Link>
                    </div>
                   }

                    <div className="mt-5 text-center" style={{position:"absolute",bottom:"5px"}}>
                      <Link
                        to={item.link}
                        className="hover:text-amber-400 font-medium duration-500"
                      >
                        Explore More{" "}
                        <i className="mdi mdi-chevron-right text-[20px] align-middle"></i>
                      </Link>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
      <div className="container relative text-center mb-5">
      <h3>
      Want to have hands-on experience?<br/>
      Scan the QR now!<br/><br/>
      </h3>
      

            <div className="grid grid-cols-4 gap-6 ">
                {QR.map((item,index) => {
                   return(<>
                   
                    <div
                  className="relative bg-white dark:bg-slate-900 p-4 rounded-md shadow dark:shadow-gray-700"
                  key={index}
                >
                        <Link to={item.url} target="_blank" className="text-center" key={index}>
                        {item.title}<br/><br/>
                        {index == 0 && <>
                          <br/></>}
                            <img src={item.image}  alt=""/>
                        </Link>
                        </div>
                        </>
                   )
                }
                )}
                
            </div>
        </div>
      
      <Footer />
      <Switcher />
    </>
  );
}
