import React,{useEffect} from "react";
import { Link } from "react-router-dom";

import bannerImg from '../assets/images/dashboard-image1 (1).png'
import bannerImg1 from '../assets/images//classic01.png'
import bannerVideo from '../assets/images/bannerVideo1.mp4'
import aboutUs from '../assets/images/aboutUs.png'

import Navbar from "../components/navbar";
import BrandLogo from "../components/brandLogo";
import Features from "../components/features";
import AboutOne from "../components/aboutOne";
import AboutTwo from "../components/aboutTwo";
import AmazingFeatures from "../components/amazingFeatures";
import AboutThree from "../components/aboutThree";
import Pricing from "../components/pricing";
import Faq from "../components/Faq";
import Blogs from "../components/blogs";
import Footer from "../components/footer";
import Switcher from "../components/switcher";

import { TypeAnimation } from 'react-type-animation';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

export default function Index(){
    useEffect(() => {
        document.documentElement.setAttribute("dir", "ltr");
        document.documentElement.classList.add('dark');
        document.documentElement.classList.remove('light');
      }, []);

    return(
        <>
        <Navbar/>
        <section className="relative overflow:hidden pt-48 after:content-[''] after:absolute after:inset-0 after:mx-auto after:w-[56rem] after:h-[56rem] after:bg-gradient-to-tl after:to-amber-400/30  after:from-fuchsia-600/30 dark:after:to-amber-400/50 dark:after:from-fuchsia-600/50 after:blur-[200px] after:rounded-full after:-z-1">
            <div className="container relative z-2">
                <div className="grid grid-cols-1">
                    <div className="text-center">
                        <h3 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-6xl">Demetrix Infotech</h3>
                        <h3 className="lg:leading-normal leading-normal text-2xl lg:text-4xl">Engineering The Intelligence of Tomorrow</h3>
                        {/* <p className="text-slate-400 dark:text-white/60 text-lg max-w-xl mx-auto mb-5" style={{fontStyle:"italic"}}>Engineering The Intelligence of Tomorrow</p> */}
                        <h5 className="font-bold lg:leading-normal leading-normal text-2xl lg:text-2xl mb-5"><br/>
                            <TypeAnimation
                                sequence={[
                                    // Same substring at the start will only be typed out once, initially
                                    'Agriculture',
                                    1000, // wait 1s before replacing "Mice" with "Hamsters"
                                    'Artificial Intelligence',
                                    1000,
                                    'Sustainability',
                                    1000,
                                    'Technology',
                                    1000,
                                    'Natural Language Processing',
                                    1000
                                ]}
                                wrapper="span"
                                speed={10}
                                className="typewrite bg-gradient-to-br from-amber-400 to-fuchsia-600 text-transparent bg-clip-text ms-4"
                                repeat={Infinity}
                                />
                                
                        </h5>
                        
                        
                    </div>
                    </div>
                    {/* <Carousel responsive={responsive}
                    infinite={true}
                    autoPlay={true}
                    autoPlaySpeed={5000}
                    > */}
                    
                    {/* <div className="relative mt-4 z-1">
                        <img src={bannerImg1} alt="" className="mover"/>
                    </div> */}
                    {/* </Carousel> */}
                    {/* <div className="relative mt-4 z-1">
                        <img src={bannerImg} alt="" className="mover"/>
                    </div> */}
                    <div className="relative mt-4 z-1">
                        <div style={{display:"flex", alignContent:"center", justifyContent:"center", alignSelf:"center"}}>
                            <video src={bannerVideo} className="rounded" autoplay="true" loop="true"  muted />
                        </div>
                    </div>
                
            </div>

           
            
            <div className="relative after:content-[''] after:absolute lg:after:-bottom-40 after:-bottom-28 after:end-0 after:start-0 after:mx-auto xl:after:w-[56rem] lg:after:w-[48rem] md:after:w-[32rem] after:w-[22rem] xl:after:h-[56rem] lg:after:h-[48rem] md:after:h-[32rem] after:h-[22rem] after:border-2 after:border-dashed after:border-slate-700/10 dark:after:border-slate-200/10 after:rounded-full after:-z-1 before:content-[''] before:absolute lg:before:-bottom-72 before:-bottom-56 before:end-0 before:start-0 before:mx-auto xl:before:w-[72rem] lg:before:w-[64rem] md:before:w-[48rem] before:w-[24rem] xl:before:h-[72rem] lg:before:h-[64rem] md:before:h-[48rem] before:h-[24rem] before:border-2 before:border-dashed before:border-slate-700/10 dark:before:border-slate-200/10 before:rounded-full before:-z-1"></div>
        </section>
        {/* <div className="relative mt-4 z-1">
                        <div style={{display:"flex", alignContent:"center", justifyContent:"center", alignSelf:"center"}}>
                            <video src={bannerVideo} className="rounded" autoplay="true" loop="true"  muted />

                        </div>
                        
                    </div> */}
        {/* overflow:hidden ---- to make the height and that blue layer joining and dotted lines disapper*/}
        <section className="relative pt-48 after:content-[''] after:absolute after:inset-0 after:mx-auto after:w-[56rem] after:h-[56rem] after:bg-gradient-to-tl after:to-amber-400/30  after:from-fuchsia-600/30 dark:after:to-amber-400/50 dark:after:from-fuchsia-600/50 after:blur-[200px] after:rounded-full after:-z-1">
            <div className="container relative z-2">
            <h3 className="md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-center">
          About Us
        </h3>
            <div className="relative mt-4 z-1">
                        <img src={aboutUs} alt="" className="rounded-lg"/>
                    </div>
            </div>
                        </section>
       

        {/* <section className="pt-6">
           <BrandLogo/>
        </section> */}

        <section className="relative md:py-24 py-16">
            {/* <AboutOne/> */}
            
            <AboutTwo/>
            <Features classlist="container relative md:py-24 py-16"/>
            {/* <AmazingFeatures/> */}
            <AboutThree/>

            {/* <div className="container relative md:mt-24 mt-16">
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">The right plans, <br/> <span className="bg-gradient-to-br from-amber-400 to-fuchsia-600 text-transparent bg-clip-text">for the right price</span></h3>

                    <p className="text-slate-400 max-w-xl mx-auto">Artificial intelligence makes it fast easy to create content for your blog, social media, website, and more!</p>
                </div>
                <Pricing/>
            </div> */}

            {/* <Faq/> */}
            <Blogs/>
        </section>
        <Footer/>
        <Switcher/>
        </>
    )
}