import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import NavLight from "../components/navbar";
import Footer from "../components/footer";
import Switcher from "../components/switcher";

export default function Privacy() {
  useEffect(() => {
    document.documentElement.setAttribute("dir", "ltr");
    document.documentElement.classList.add("dark");
    document.documentElement.classList.remove("light");
  }, []);
  return (
    <>
      <NavLight />
      <section className="relative md:pt-44 pt-32 pb-8 bg-gradient-to-b from-amber-400/20 dark:from-amber-400/40 to-transparent">
        <div className="container relative">
          <div className="grid grid-cols-1 text-center mt-6">
            <div>
              <h5 className="md:text-4xl text-3xl md:leading-normal leading-normal tracking-wider font-semibold mb-0">
                Privacy Policy
              </h5>
            </div>

            <ul className="tracking-[0.5px] mb-0 inline-block mt-5">
              <li className="inline-block capitalize font-medium duration-500 ease-in-out hover:text-amber-400">
                <Link to="/">Home</Link>
              </li>
              <li className="inline-block text-base mx-0.5 ltr:rotate-0 rtl:rotate-180">
                <i className="mdi mdi-chevron-right"></i>
              </li>
              <li
                className="inline-block capitalize font-medium duration-500 ease-in-out text-amber-400"
                aria-current="page"
              >
                Privacy
              </li>
            </ul>
          </div>
        </div>
      </section>
      <section className="relative md:py-24 py-16">
        <div className="container relative">
          <div className="md:flex justify-center">
            <div className="md:w-3/4">
              <div className="p-6 bg-white dark:bg-slate-900 shadow dark:shadow-gray-800 rounded-md">
                <h5 className="text-xl font-semibold mb-4">Overview:</h5>
                <p className="text-slate-400">
                  Your privacy is important to us. Our goal is to provide you
                  with a personalized online experience that provides you with
                  the information, resources, and services that are most
                  relevant and helpful to you.
                </p>
                <h5 className="text-xl font-semibold mb-4  mt-8">
                  Third Party Sites:
                </h5>
                <p className="text-slate-400">
                  This website contains links to other sites. This Privacy
                  Policy applies solely to information collected at this
                  website. Please be aware that Demetrix is not responsible for
                  the privacy practices of third party sites.
                </p>

                <h5 className="text-xl font-semibold mb-4 mt-8">
                  Information Collected:
                </h5>
                <ul className="list-none text-slate-400 mt-4">
                  <li className="flex mt-2">
                    <i className="mdi mdi-arrow-right text-amber-400 text-lg align-middle me-2"></i>
                    <div>
                      <h5 className="font-bold">
                        Personal&nbsp;Information:&nbsp;
                      </h5>
                      <p>
                        Demetrix collects personal information from visitors to
                        our website when, for example, you fill out a contact
                        form, subscribe to any mailing lists, provide feedback,
                        or register to obtain a service from us. This is
                        personal information directly communicated by you to
                        Demetrix and allows us to provide a service to you.
                        <br />
                        The types of personal information you provide to us on
                        these pages may include name, address, phone number and
                        e-mail address.
                      </p>
                    </div>
                  </li>
                  <li className="flex mt-2">
                    <i className="mdi mdi-arrow-right text-amber-400 text-lg align-middle me-2"></i>
                    <div>
                      <h5 className="font-bold">
                        Aggregate&nbsp;Information:&nbsp;
                      </h5>
                      <p>
                        Demetrix may gather aggregate information, which refers
                        to information your computer automatically provides to
                        us and that cannot be tied back to you as a specific
                        individual. Examples include referral data (the web
                        sites you visited just before and just after our site),
                        the pages viewed, time spent at our Web site, and
                        Internet Protocol (IP) addresses. An IP address is a
                        number that is automatically assigned to your computer
                        whenever you access the Internet. For example, when you
                        request a page from one of our sites, our servers log
                        your IP address to create aggregate reports on user
                        demographics and traffic patterns and for purposes of
                        system administration.
                      </p>
                    </div>
                  </li>
                  <li className="flex mt-2">
                    <i className="mdi mdi-arrow-right text-amber-400 text-lg align-middle me-2"></i>
                    <div>
                      <h5 className="font-bold">Log&nbsp;Files:&nbsp;</h5>
                      <p>
                        Every time you request or download a file from the web
                        site, Demetrix may store data about these events and
                        your IP address in a log file. Demetrix may use this
                        information to analyze trends, administer the web site,
                        track users’ movements, and gather broad demographic
                        information for aggregate use or for other business
                        purposes.
                      </p>
                    </div>
                  </li>
                  <li className="flex mt-2">
                    <i className="mdi mdi-arrow-right text-amber-400 text-lg align-middle me-2"></i>
                    <div>
                      <h5 className="font-bold">Cookies:&nbsp;</h5>
                      <p>
                        Our site may use a feature of your browser to set a
                        “cookie” on your computer. Cookies are small packets of
                        information that a web site’s computer stores on your
                        computer. Demetrix websites can then read the cookies
                        whenever you visit our site. We may use cookies in a
                        number of ways, such as to save your password so you
                        don’t have to re-enter it each time you visit our site,
                        to deliver content specific to your interests and to
                        track the pages you’ve visited. These cookies allow us
                        to use the information we collect to customize your
                        experience so that your visit to our site is as relevant
                        and as valuable to you as possible.
                        <br />
                        Most browser software can be set up to deal with
                        cookies. You may modify your browser preference to
                        provide you with choices relating to cookies. You have
                        the choice to accept all cookies, to be notified when a
                        cookie is set or to reject all cookies. If you choose to
                        reject cookies, certain of the functions and
                        conveniences of our web site may not work properly, and
                        you may be unable to use those of Demetrix services that
                        require registration in order to participate, or you
                        will have to re-register each time you visit our site.
                        Most browsers offer instructions on how to reset the
                        browser to reject cookies in the “Help” section of the
                        toolbar. We do not link non-personal information from
                        cookies to personally identifiable information without
                        your permission.
                      </p>
                    </div>
                  </li>
                </ul>

                <h5 className="text-xl font-semibold mb-4 mt-8">
                  How We Use Your Information:
                </h5>
                <p className="text-slate-400">
                  This information will only be used for the purpose for which
                  you have provided it. It will not be added to a mailing list
                  or used for any other purpose without your consent.
                  <br />
                  Demetrix uses your automatically generated information for
                  statistical analysis purposes to determine the popularity and
                  common use of this website, analyse trends, administer the
                  site, and to track any unauthorised or damaging visits which
                  may cause risk to the operation of the website.
                </p>
                
                <h5 className="text-xl font-semibold mb-4 mt-8">
                  Information Sharing:
                </h5>
                <p className="text-slate-400">
                Demetrix does not sell, rent, or lease any individual’s personal information or lists of email addresses to anyone for marketing purposes, and we take commercially reasonable steps to maintain the security of this information.
                </p>
                
                <h5 className="text-xl font-semibold mb-4 mt-8">
                  Security:
                </h5>
                <p className="text-slate-400">
                Demetrix make every effort to protect personal information by users of the web site, including using firewalls and other security measures on its servers. No server, however, is 100% secure, and you should take this into account when submitting personal or confidential information about yourself on any web site, including this one.</p>
                
                <h5 className="text-xl font-semibold mb-4 mt-8">
                Changes to this privacy policy:
                </h5>
                <p className="text-slate-400">
                Demetrix may edit this policy from time to time.</p>
                {/* <div className="mt-8">
                  <Link
                    to=""
                    className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-amber-400 hover:bg-amber-500 border-amber-400 hover:border-amber-500 text-white rounded-md"
                  >
                    Print
                  </Link>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
      <Switcher />
    </>
  );
}
