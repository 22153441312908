import React from "react";
import { Link } from "react-router-dom";
import aboutImg from "../assets/images/anim-1.mp4";

import { FiCheckCircle, MdKeyboardArrowRight } from "../assets/icons/vander";

export default function AboutOne() {
  return (
    <>
      <div className="container relative">
        <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold text-center">
          About Us
        </h3>
        <p className="text-slate-400">
          Welcome to the innovative world of Demetrix, where passion meets
          technology to bridge the digital divide. Our journey began with a
          strategic move, as the sustainable technology company SNIPL joined
          hands with us for technical advancement. This significant step has not
          only enriched our capabilities but has also set a new trajectory for
          the products initially developed by SNIPL.
          <br /><br/>
          At Demetrix, we are more than just a technology firm; we are a dream
          realized. Our goal is to develop AI-based products that resonate with
          the needs of diverse stakeholders. We understand that technology
          should be accessible, user-friendly, and affordable. That's why our
          products are designed to bring ease of use and affordability to the
          forefront, ensuring that everyone, regardless of their background, can
          benefit from our innovations.</p>

        <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-6  mt-8">
          {/* <div className="relative overflow-hidden rounded-lg border border-amber-400/5 bg-gradient-to-tl to-amber-400/30  from-fuchsia-600/30 dark:to-amber-400/50 dark:from-fuchsia-600/50 ps-6 pt-6 lg:me-8">
            <img
              src={aboutImg}
              className="ltr:rounded-tl-lg rtl:rounded-tr-lg"
              alt=""
            />
          </div> */}
           <video
              src={aboutImg}
              autoplay="true" loop="true" muted 
              className="rounded-lg"
              // alt=""
            />

          <div className="">
            <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              Our Focus
            </h3>

            <ul className="list-none text-slate-400 mt-4">
              <li className="mb-2 flex items-center">
                <FiCheckCircle className="text-amber-400 h-5 w-5 me-2" />
                Bridging people and technology
              </li>
              <li className="mb-2 flex items-center">
                <FiCheckCircle className="text-amber-400 h-5 w-5 me-2" />
                Innovate new ideas and convert it as products
              </li>
              <li className="mb-2 flex items-center">
                <FiCheckCircle className="text-amber-400 h-5 w-5 me-2" />
                Finding and motivating stakeholders towards technology
              </li>
            </ul>

            <div className="mt-4">
              <Link
                to="/aboutus"
                className="hover:text-amber-400 font-medium duration-500 inline-flex items-center"
              >
                Find Out More{" "}
                <MdKeyboardArrowRight className="ms-1 text-[20px]" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
