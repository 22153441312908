import React from "react";
import { Link } from "react-router-dom";

import aboutImg from "../assets/images/about2.png";

import { FiCheckCircle, MdKeyboardArrowRight } from "../assets/icons/vander";

export default function AboutTwo() {
  return (
    <>
      <div className="container relative md:mt-24 mt-16">
        <div className="grid md:grid-cols-2 grid-cols-1 items-center gap-6">
          <div className="relative order-1 md:order-2">
            {/* <div className="relative overflow-hidden rounded-lg border border-amber-400/5 bg-gradient-to-tl to-amber-400/30  from-fuchsia-600/30 dark:to-amber-400/50 dark:from-fuchsia-600/50 pe-6 pt-6 lg:ms-8">
              <img
                src={aboutImg}
                className="ltr:rounded-tr-lg rtl:rounded-tl-lg"
                alt=""
              />
            </div> */}
            <img
                src={aboutImg}
                className="rounded-lg"
                alt=""
              />
          </div>

          <div className="order-2 md:order-1">
            <h4 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">
              Why Choose Us?
            </h4>
            <p className="text-slate-400">
              Demetrix has a deep and comprehensive understanding of the
              sustainability issues on-ground and beyond farm-level.
              <br />
              <br /> In our earlier avatar, SNIPL, Demetrix has introduced an
              array of digital tools for sectors and communities around the
              globe. In 2020, it focused on increasing access to mobile
              applications and scaling up digital interventions through
              Solidaridad Network India Private Limited (SNIPL).
              <br />
              <br /> In Asia, SNIPL worked to increase mobile access,
              connecting more than 300,000 farmers across our 31 apps, including
              i2i (Dairy), I3SF (Sugarcane), TRINITEA (Tea), BENTANG SAWIT
              (Palm), E-Sugarcanebook (Farm Diary), Solibot (Multilingual Chat
              bot), Smart-bin (Waste/Recycle Mgmt) and GRIT (Gender Inclusivity
              tool) among others. The apps are implemented in Asia and Europe.
            </p>
            {/* <ul className="list-none text-slate-400 mt-4">
                            <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2"/> Digital Marketing Solutions for Tomorrow</li>
                            <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2"/> Our Talented & Experienced Marketing Agency</li>
                            <li className="mb-2 flex items-center"><FiCheckCircle className="text-amber-400 h-5 w-5 me-2"/> Create your own skin to match your brand</li>
                        </ul> */}

            <div className="mt-4">
              <Link
                to="/aboutus"
                className="hover:text-amber-400 font-medium duration-500 inline-flex items-center"
              >
                Find Out More{" "}
                <MdKeyboardArrowRight className="ms-1 text-[20px]" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
