import blog1 from "../assets/images/blog/10.jpg"
import blog2 from "../assets/images/blog/11.jpg"
import blog3 from "../assets/images/blog/14.jpg"
import blog4 from "../assets/images/blog/13.jpg"
import blog5 from "../assets/images/blog/15.jpg"
import blog6 from "../assets/images/blog/16.jpg"
import blog7 from "../assets/images/blog/17.jpg"
import blog8 from "../assets/images/blog/18.jpg"
import blog9 from "../assets/images/blog/19.jpg"
import blog10 from "../assets/images/blog/20.png"
import blog11 from "../assets/images/blog/21.jpg"
import blog12 from "../assets/images/blog/22.jpg"
import blog13 from "../assets/images/blog/23.png"
import client1 from '../assets/images/client/01.jpg'
import client2 from '../assets/images/client/02.jpg'
import client3 from '../assets/images/client/03.jpg'
import client4 from '../assets/images/client/04.jpg'
import client5 from '../assets/images/client/05.jpg'
import client6 from '../assets/images/client/06.jpg'
import client7 from '../assets/images/client/07.jpg'
import client8 from '../assets/images/client/08.jpg'


export const blogData = [
    {
        id:1,
        image:blog1,
        title:'Mr. Pramod John speaking about the innovations in the tea sector at the 8th India Tea Forum Hosted by Confederation of Indian Industry in West Bengal, India.',
        // author:'Calvin Carlo',
        // client:client1,
        // date:'Sep 13, 2023'
    },
    {
        id:2,
        image:blog2,
        title:'Product demonstration at Kolkata, West Bengal, India.',
    },
    {
        id:3,
        image:blog10,
        title:'Onboarding under Assam AgTech OCP, an initiative of Software Technology Park of India, Ministry of Electronics & IT, Govt. of India.',
       
    },
    
    // {
    //     id:4,
    //     image:blog4,
    //     title:'Demo of Demetrix products along with other Agri Startups on Agriculture Day hosted by Deshpande Foundation on 24th January, 2023 at Bangalore International Centre, Bangalore, Karnataka, India',
    //     author:'Mari Harrington',
    //     client:client4,
    //     date:'March 13, 2023'
    // },
    {
        id:5,
        image:blog5,
        title:'Installation of Trinitea and Traceability applications in Bangladesh.',
       
    },
    {
        id:6,
        image:blog6,
        title:'With Solidaridad Country Manager, Ms. Mary Mkonyi and The Minister of Agriculture, Irrigation, Natural Resources and Livestock, Zanzibar.',
       
    },
    {
        id:7,
        image:blog7,
        title:'ETL Hackathon in Zambia.',
       
    },
    {
        id:8,
        image:blog8,
        title:'Meeting with soil scientists along with Solidaridad Asia at lISS (Indian Institute of Soil Sciences), Bhopal, Madhya Pradesh, India.',
        
    },
    {
        id:9,
        image:blog9,
        title:'Meeting with Dole Team at Thailand for installation of Dole-Sunshine for All.',
       
    },
    {
        id:10,
        image:blog3,
        title:'Ballotronix - The Tea Leaf Analyser demonstration at United Planters Association of South India at Coonoor, Tamilnadu, India',
        
    },
    {
        id:11,
        image:blog11,
        title:'Presentation of Tracebility at consultation meeting with small tea growers at Siliguri, West Bengal India',
       
    },
    {
        id:12,
        image:blog12,
        title:'Pineapple quality determination process explained by Dole Team at Thailand',
       
    },
    {
        id:13,
        image:blog13,
        title:'Visit to the K.R Puram police station, Bangalore, India to demonstrate our QR code-based Xplorease platform.',
    },
    
]