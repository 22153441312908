import product1Img from "../assets/images/products/software/product1.jpg";
import product2Img from "../assets/images/products/software/product2.jpg";
import product3Img from "../assets/images/products/software/product3.png";

import ballotronixImg from "../assets/images/products/ai-hardware/ballotonix.png";
import bal1 from "../assets/images/products/ai-hardware/bal-1.jpg";
import bal2 from "../assets/images/products/ai-hardware/bal-2.jpg";
import probeImg from "../assets/images/products/ai-hardware/soil-probe.png";
import probe1 from "../assets/images/products/ai-hardware/probe-1.jpg";
import probe2 from "../assets/images/products/ai-hardware/probe-2.jpg";
import metImg from "../assets/images/products/ai-hardware/agri-met.png";
import met1 from "../assets/images/products/ai-hardware/met-1.jpg";
import met2 from "../assets/images/products/ai-hardware/met-2.jpg";

import xploreEaseImg from "../assets/images/products/ai-software/xploreEase.png";
import xploreEaseImg1 from "../assets/images/products/ai-software/xploreEaseQR.png";
import xploreEaseImg2 from "../assets/images/xploreEase.png";
import classIQ from "../assets/images/products/ai-software/classIQ.jpg";
import classIQ1 from "../assets/images/products/ai-software/classIQ2.jpg";
import classIQ2 from "../assets/images/products/ai-software/classIQ3.jpeg";
import assureImg from "../assets/images/products/ai-software/Soli-Assure.jpg";
import farmImg from "../assets/images/products/ai-software/Farmwise.png";
import traceImg from "../assets/images/products/ai-software/agri-trail.png";
import eLearnImg from "../assets/images/products/ai-software/e-learn.png";
import trinityImg from "../assets/images/products/ai-software/trinity.png";
import assure1 from "../assets/images/products/ai-software/assure1.jpg";
import assure2 from "../assets/images/products/ai-software/assure2.jpg";
import farm1 from "../assets/images/products/ai-software/farmwise1.png";
import farm2 from "../assets/images/products/ai-software/farm2.jpg";
import trace1 from "../assets/images/products/ai-software/trace1.jpg";
import trace2 from "../assets/images/products/ai-software/agri-trail.png";
import eLearn1 from "../assets/images/products/ai-software/elearn1.png";
import eLearn2 from "../assets/images/products/ai-software/elearn2.png";
import trinity1 from "../assets/images/products/ai-software/TriniTea1.png";

import agribot from "../assets/images/products/ai-software/agribot.png"
import agribot1 from "../assets/images/products/ai-software/agribot1.png"
import agribot2 from "../assets/images/products/ai-software/agribot2.png"

import farmLogImg from "../assets/images/products/ai-software/farm-log1.png";
import farmLog1 from "../assets/images/products/ai-software/farm-log3.png"
import farmLog2 from "../assets/images/products/ai-software/farm-log2.png"
import carbonImg from "../assets/images/products/ai-software/carbon.png";
import carbon1 from "../assets/images/products/ai-software/carbon1.png";
import carbon2 from "../assets/images/products/ai-software/carbon2.png";
import crop4seeImg from "../assets/images/products/ai-software/crop4see.png";
import crop4see1 from "../assets/images/products/ai-software/crop4see1.png";
import crop4see2 from "../assets/images/products/ai-software/crop4see2.png";
import dLenzImg from "../assets/images/products/ai-software/d-lenz.png";
import dLenz1 from "../assets/images/products/ai-software/d-lenz1.png";
import dLenz2 from "../assets/images/products/ai-software/d-lenz2.png";
import smartBinImg from "../assets/images/products/ai-software/smart-bin.png";
import smartBin1 from "../assets/images/products/ai-software/smart-bin1.png";
import smartBin2 from "../assets/images/products/ai-software/smart-bin2.png";


export const productData = [
  {
    id: 1,
    productType: "1",
    image: product1Img,
    title: "Traceability Solutions for Tea Industry",
    tagline: "",
    desc: "Technology-powered traceability solution SoliTrace transforms agribusinesses into purpose-driven brands, delivers better value for farmers and empowers consumers. A more convenient market and finance system, as well as economic rewards for farmers.",
    link: "/product-details/1",
  },
  {
    id: 2,
    productType: "1",
    image: product2Img,
    title: "Empowerment of Women in Dairy",
    tagline: "",
    desc: "Demetrix's digital model for direct earnings to woman farmers selling milk. The significance of Demetrix's digital model for empowering woman farmers and promoting gender equality in the agricultural sector.",
    link: "",
  },
  {
    id: 3,
    productType: "1",
    image: product3Img,
    title: "Crop Predictability in Fruits and Vegetables",
    tagline: "",
    desc: "In the world of agriculture, predictability plays a crucial role in ensuring sustainable food production. Farmers and agricultural researchers have long been striving to improve crop predictability, especially when it comes to fruits and vegetables. The ability to forecast yields accurately can lead to increased efficiency, reduced waste, and better decision-making for farmers.",
    link: "",
  },
  {
    id: 4,
    productType: "2",
    image: xploreEaseImg,
    title: "XplorEase",
    tagline: "Instant Chatbot Generator",
    desc: "XplorEase revolutionizes information access using QR code technology, offering an intuitive and efficient way to explore and engage with various locations.",
    image1: xploreEaseImg1,
    image2: xploreEaseImg2,
    para1:[
      "Instant Access",
      "Seamless Information Retrieval: Provides immediate access to relevant data.",
      "Effortless Integration.",
      "No Technical Barriers: Integrates smoothly into various environments.",
      "Detailed Information.",
      "Comprehensive Content: Offers rich and detailed information about locations.",
      "User-Friendly Interface.",
      "Intuitive Design: Ensures a smooth and enjoyable user experience.",
      
    ],
    para2:[
      "No Downloads, No Installations.",
      "Hassle-Free Access: Provides information with a simple scan.",
      "Customizable Solutions.",
      "Tailored Experiences: Allows for content customization to suit specific needs.",
      "Enhanced Accessibility.",
      "Broad Reach: Accessible across various devices for information anytime, anywhere."
    ],
    
    // link: "",
  },
  {
    id: 5,
    productType: "2",
    image: classIQ,
    title: "ClassIQ",
    tagline: "Learn on the Go",
    desc: "ClassIQ is a comprehensive solution designed to transform traditional classrooms into dynamic learning hubs.",
    image1: classIQ1,
    image2: classIQ2,
    para1:[
      "Intelligent Teaching Tools: Enables dynamic lesson creation with multimedia, simulations, and interactive quizzes.",
      "Student-Focused Assessments: Allows for effective gauging of student understanding and progress.",
      "Data-Driven Insights: Analyzes student performance for tailored instruction.",
    ],
    para2:[
      "Collaborative Learning Environment: Fosters active engagement and group collaboration.",
      "Accessibility and Flexibility: Accommodates various classroom settings and instructional formats.",
      "Secure and Reliable Platform: Ensures data security and reliability with robust encryption protocols."
    ],
    link: "",
  },
  {
    id: 6,
    productType: "2",
    image: assureImg,
    title: "Soli-Assure",
    tagline: "Solution for Maintian Standards of Soil & Crops.",
    desc: "It is a cost effective tool for assessments/certifications which monitor the continual improvement of farmers.",
    image1: assure1,
    image2: assure2,
    para1:
      "SoliAssure tool is an efficient and cost-effective solution for standards and certification digitization. It is an integrated tool for assessment to gauge sustainability levels of stakeholders, identifying risks, planning, effective implementation of practices and continual improvement. The tool facilitates in identifying current gaps and segregating different issues at different levels for scope of improvement and accordingly the recommendations are generated along with a work plan for further improvement. It also provides an in-built MIS system and efficient extension through online advisory. Organizes an internal audit to adopt recommendations. Provide scores on each of the standards and certification and share score cards on stakeholder’s readiness for certification. Readjusts Work Plan for improvements if major non-conformities are found. Provision to conduct external audits is made feasible.",
    para2:
      "This tool is essential for self-assessments, identifying potential risks, planning, executing, and communicating the progress for continuous improvement of standards.",
    // video:"https://www.youtube.com/embed/pcqXjyZz_0A",
  },
  {
    id: 7,
    productType: "2",
    image: farmImg,
    title: "Farm-Wise",
    tagline: "Farmers tool for self-assessment and certification",
    desc: "Farm-Wise is a robust self-assurance tool designed to streamline the digitization of standards and certifications in agriculture. It helps stakeholders assess sustainability levels, identify risks, plan strategically, implement best practices, and ensure continual improvement.",
    image1: farm1,
    image2: farm2,
    para1: [
      "Internal Audit Organization: Organizes thorough internal audits to adopt best sustainability practices.",
      "Certification Readiness Scores: Provides detailed scores on standards and certification criteria.",
      "Work Plan Adjustment: Adjusts work plans to address major non conformities.",
      "Facilitates External Audits: Ensures seamless transition from internal to external assessments."
    ],
    para2: [
      "Risk Identification and Planning: Identifies potential risks and provides strategic plans for mitigation.",
      "Continual Improvement: Supports ongoing assessments and recommendations for continual improvement.",
      "Online Advisory Services: Offers expert guidance and support for implementing best practices."
    ],
    video: "https://www.youtube.com/embed/Pn1_QBqiGv8",
  },
  {
    id: 8,
    productType: "2",
    image: traceImg,
    title: "Agri-Trail",
    tagline: "Transparency and Sustainability in Your Supply Chain.",
    desc: "Agri-Trail is a technology-powered traceability solution that transforms agribusinesses into purpose-driven brands, delivers better value for farmers, and empowers consumers through supply chain transparency.",
    image1: trace1,
    image2: trace2,
    para1: [
      "Higher Brand Equity for Businesses: Digital transformation of agriculture supply chains with sustainability at the core.",
      "Better Value for Farmers: Equal participation in a transparent and traceable supply chain with unique data governance. ",
      "More Power to Consumers: Conscious consumers can trace product provenance and the lives of the producers. ",
    ],
    para2: [
      "It Makes the best use of the data provided by all the devices employed or partly as needed: Soil Probe, Agrimet, Agri Log, Crop4see, Agri trail, Farmwise, Agribot, Ballotronix (in case of tea sector)",
    ],
    video: "https://www.youtube.com/embed/f1WLmH5TXvE",
  },
  {
    id: 9,
    productType: "2",
    image: eLearnImg,
    title: "E-Learning",
    tagline: "Learn on the Go",
    desc: "E-Learning facilitates the creation and management of educational courses and assessments tailored for farmers and agricultural stakeholders.",
    image1: eLearn1,
    image2: eLearn2,
    para1:[
      "Expert Content Creation: Empowers experts to design comprehensive courses.",
      "Customizable Modules: Courses can be tailored to specific needs and challenges.", 
      "Course-Based Evaluations: Includes tools for creating assessments and quizzes.",
      "Progress Tracking: Monitors learner progress and performance.",
    ],
    para2:
     [
      "Certification Issuance: Issues certifications upon course completion.", 
      "Credential Recognition: Validates skills and knowledge in specific areas.", 
      "Accessible Learning: Designed to be user-friendly for all learners.",
      "Multimedia Integration: Supports various forms of multimedia for engaging learning."
     ],
    // video:"https://www.youtube.com/embed/Pn1_QBqiGv8",
  },
  {
    id: 10,
    productType: "2",
    image: trinityImg,
    title: "Trinitea",
    tagline:
      "Brings a perspective to the sustainability standards in tea supply chain.",
    desc: "Trinitea is a self-assurance application that brings a fresh perspective to the conventional sustainability standards in the tea supply chain.",
    image1: trinity1,
    image2: assure1,
    para1:
      "Trinitea is a self-assurance application that brings a fresh perspective to the conventional sustainability standards in the tea supply chain.",
    para2:
      "It contains questions seeking information about the producers and their agricultural practices. On successful completion of the assessment, Trinitea provides an overall rating based on their practices.",
    video: "https://www.youtube.com/embed/QrVFIcPOLWI",
  },
  {
    id: 11,
    productType: "3",
    image: ballotronixImg,
    title: "Ballotronix",
    tagline: "Tea leaf Reader",
    desc: "Ballotronix automates the grading of tea leaves using advanced computer vision and deep learning technologies.",
    image1: bal1,
    image2: bal2,
    para1:[
      "High Precision: Delivers quick and accurate grading of tea leaves.",
      "Standard Compliance: Adheres to factory and tea board standards.",
      "Bias-Free Evaluation:  Eliminates human bias in quality assessment.",
      "Better Pricing:  Ensures standardized and transparent grading."
    ],
      
    para2:[
      "Practice Monitoring: Analyzes historical data for optimized cultivation methods.",
      "Leaf Disease Warning: Includes disease detection for timely remedial actions."
    ],
    video: "https://www.youtube.com/embed/pcqXjyZz_0A",
  },
  {
    id: 12,
    productType: "3",
    image: probeImg,
    title: "Soil-Probe",
    tagline: "Instant Soil Analyser.",
    desc: "Soil-Probe provides instant soil analysis with ease, offering a portable device that determines 8 soil parameters (N, P, K, salinity, soil moisture, soil temperature, electrical conductivity and pH) and provides land contour mapping.",
    image1: probe1,
    image2: probe2,
    para1: [
      "Instant Field Testing:- ",
      "On-the-Go Analysis: Provides real-time results directly in the field.",
      "Comprehensive Soil Analysis:- ",
      "Nutrient and pH Levels: Measures essential soil parameters.",
      
    ],

      para2:[
      "Data Storage and Analysis:- ",
      "Results Database: Stores test results for historical analysis.",
      "Land Contour Mapping:- ",
      "Generates contour maps for effective irrigation and fertilizer application."
      ],
    video: "https://www.youtube.com/embed/fRGzz6FhBGY",
  },
  {
    id: 13,
    productType: "3",
    image: metImg,
    title: "Agri-Met",
    tagline: "Hyperlocal weather station",
    desc: "Agri-Met is a hyperlocal weather station leveraging AI and high-precision sensors for weather updates, soil and plant monitoring, and pest and animal detection.",
    image1: met1,
    image2: met2,
    para1:[
      "Standard:- ",
      "Hyperlocal Weather Forecasting.",
      "Advanced Forecasts: Provides precise weather forecasts.",
      "Efficient Resource Management.",
      "Cost Reduction: Optimizes input use for significant cost savings.",
      "Regular Monitoring."
    ],
    para2:[
      "Pro Version:- ",
      "Soil Parameters in addition to features of standard version",
      "Pro plus Version:- ",
      "Pest Detection.",
      "Targeted Pest Management: Provides real-time information on pest activity.",
      "Animal Intrusion Alerts.",
      "Intrusion Detection: Identifies potential wildlife intrusions."
    ],
    // video:"",
  },
  {
    id: 14,
    productType: "2",
    image: agribot,
    title: "Agri-Bot",
    tagline: "Farmer’s expert Guide",
    desc: "Agri-Bot is a farming companion offering personalized, real-time assistance to help farmers optimize crop production, enhance decision-making, and achieve greater success. It features an intuitive interface, natural language processing capabilities, and comprehensive tools.",
    image1: agribot1,
    image2: agribot2,
    para1:[
      "Efficiency: Provides real-time access to valuable information about crops, farming practices, weather forecasts, and market prices.",
      "Productivity: Offers expert guidance to improve crop yields and quality.",
      "Risk Management: Helps mitigate risks with accurate forecasts, pest management strategies, and market updates.",
    ],
    para2:[
      "Sustainability: Promotes sustainable farming practices.",
      "Accessibility: Offers personalized assistance tailored to specific needs.",
      "Empowerment: Provides timely, accurate information and expert guidance for confident decision-making.",
    ],
    // video:"",
  },
  {
    id: 15,
    productType: "2",
    image: farmLogImg,
    title: "Farm-Log",
    tagline: "Farmer’s Daily Dairy",
    desc: "Farm-Log is an advanced farm management tool designed to streamline various aspects of farm operations.",
    image1: farmLog1,
    image2: farmLog2,
    para1:[
      "Comprehensive Record-Keeping.",
      "Operation Tracking: Document all aspects of farm operations, including land preparation, cultivation, maintenance, harvest, and post-harvest activities.",
      "Real-Time Access: Access data anytime for timely decision-making and efficient farm management.",
      "Dynamic Advisory Content",
    ],
    para2:[
      "Seasonal Responses: Provides guidance and recommendations for seasonal and emerging challenges.",
      "Customizable Training: Create and update training modules tailored to specific practices and needs.",
      "Digitization Capabilities.",
      "Manuals and Modules: Digitize existing training manuals and educational modules for easier access and updates."
    ],
    // video:"",
  },
  {
    id: 16,
    productType: "2",
    image: carbonImg,
    title: "Carbon Sequester",
    tagline: "Farmer’s guide to carbon",
    desc: "Carbon Sequester is an advanced tool that provides farmers with detailed insights into carbon sequestration achieved through regenerative agriculture practices.",
    image1: carbon1,
    image2: carbon2,
    para1:[
      "Integrated Carbon Measurement.",
      "Comprehensive Assessment: Measures both carbon emissions and sequestration using Cool Farm Tools.",
      "Regenerative Agriculture Focus: Tailored for farms using practices that enhance soil health and increase carbon storage.",
      "Revenue Generation Potential",
      "Carbon Credits: Calculates carbon captured in excess of standard levels for potential sale on carbon markets.",
    ],
    para2:[
      "Sustainability Incentives: Helps farmers access sustainability incentives and programs.",
      "Data Integration and Accuracy.",
      "Farm-Log Dependency: Relies on data from Farm-Log for accurate carbon measurement.",
      "Enhanced Reporting: Provides detailed reporting and analysis for informed decision-making."
    ],
    // video:"",
  },
  {
    id: 17,
    productType: "2",
    image: crop4seeImg,
    title: "Crop4See",
    tagline: "Yield prediction tool",
    desc: "Crop4See is a sophisticated tool designed to forecast crop production using advanced technologies such as satellite and drone imagery, combined with historical data.",
    image1: crop4see1,
    image2: crop4see2,
    para1:[
      "Advanced Crop Forecasting.",
      "Imagery Analysis: Utilizes satellite and drone imagery for precise crop production forecasting.",
      "Historical Data Integration: Enhances yield predictions by analyzing historical crop data.",
      "Standardized Production Quantity.",
      "Yield Prediction: Provides precise estimates of crop quantities.",
      "Production Planning: Supports standardized production strategies and resource management.",
      "Cost Monitoring and Adjustment.",
      "Financial Oversight: Helps monitor costs in relation to predicted yields for better budgeting.",
    ],
    para2:[
      "Operational Adjustments: Allows for adjustments in operations based on production outcomes.",
      "GIS Technology Integration.",
      "Spatial Analysis: Uses GIS for mapping, spatial analysis, and visualization.",
      "Field Mapping: Provides detailed field mapping for accurate forecasting.",
      "Data Visualization: Presents data in visual formats for easier interpretation."
    ],
    // video:"",
  },
  {
    id: 18,
    productType: "2",
    image: dLenzImg,
    title: "D-Lenz",
    tagline: "Leaf disease detector",
    desc: "D-Lenz is an advanced AI-based tool designed to assist farmers in identifying leaf diseases and pests using artificial intelligence and extensive agricultural disease data.",
    image1: dLenz1,
    image2: dLenz2,
    para1:[
      "Visual Detection: Identifies visually detectable diseases and pests using smartphone cameras.",
      "AI-Based Analysis: Provides accurate disease and pest identification through AI algorithms.",
    ],
    para2:[
      "Comprehensive Database: Covers a wide range of pests and diseases for various crops.",
      "User-Friendly Interface: Easy to use for farmers with minimal technical knowledge.",
    ],
    // video:"",
  },
  {
    id: 19,
    productType: "3",
    image: smartBinImg,
    title: "Smart Bins",
    tagline: "Smart Waste Management",
    desc: "Smart Bins leverage IoT technology for real-time waste management notifications and enhanced efficiency in garbage collection processes.",
    image1: smartBin1,
    image2: smartBin2,
    para1:[
      "Fullness Detection: Monitors waste levels and sends alerts when bins are full.",
      "Status Updates: Provides continuous monitoring and timely interventions.",
      "Optimized Routes: Optimizes waste collection routes and schedules.",
      "Reduced Overflow: Prevents bin overflow and maintains cleanliness.",
      "Seamless Communication: Enables real-time connectivity between bins, collection centers, and recyclers. ",
    ],
    para2:[
      "Data Collection: Collects data on waste generation patterns for improved strategies.",
      "Low-Cost Implementation: Cost-effective solution for waste management.",
      "Maintenance Savings: Reduces unnecessary pickups and optimizes routes.",
      "Enhanced Cleanliness: Maintains cleaner public spaces.",
      "Waste Reduction: Supports responsible disposal practices. "
    ],
    // video:"",
  },
];
