export const careerData = [
    
        {
            id: 1,
            name:"Front-End Developer",
            field: "IT",
            place: "Bangalore, India",
            type:"Full-Time",
            jd: ["We are seeking a talented Frontend Developer with expertise in React and Next.js to join our startup team. As a small team of energetic individuals, you will have the opportunity to work directly with the core team and contribute significantly to our mission."],
            responsibilities: [
                "Develop user-friendly web applications using React.js and Next.js.",
                "Collaborate with back-end developers and designers to improve usability.",
                "Optimize applications for maximum speed and scalability.",
                "Implement responsive designs and ensure cross-browser compatibility.",
                "Write functional requirement documents and guides.",
                "Stay up-to-date on emerging technologies.",
            ],
            qualifications: [
                "Bachelor's degree in Computer Science, Engineering, or a related field.",
                "Proven experience as a Frontend Developer with expertise in React.js and Next.js.",
                "Strong proficiency in JavaScript, including DOM manipulation and the JavaScript object model.",
                "Familiarity with RESTful APIs and modern authorization mechanisms, such as JSON Web Token.",
                "Experience with popular React.js workflows (such as Flux or Redux).",
                "Familiarity with modern front-end build pipelines and tools.",
                "Experience with common front-end development tools such as Babel, Webpack, NPM, etc.",
                "Excellent communication and collaboration skills.",
                "Ability to work independently and as part of a team.",
                "Submission of a Resume, Cover Letter, and portfolio showcasing at least 3 different previous projects demonstrating expertise in React and Next.js.",
            ],
            benefits: [
                "Competitive salary and performance-based incentives with the opportunity for additional rewards based on performance.",
                "Comprehensive health insurance plans.",
                "This is an in-office position.",
                "Professional development opportunities and tuition reimbursement.",
                "Fun and collaborative work environment with a small team of energetic individuals.",
                "Opportunity for career growth and advancement within a rapidly growing startup company.",
            ]
        },
        // {
        //     id: 2,
        //     name:"Back-End Developer",
        //     field: "IT",
        //     place: "Bangalore, India",
        //     type:"Intern",

        // },
        {
            id: 2,
            name:"Marketing and Sales Specialist",
            field: "Marketing",
            place: "Bangalore, India",
            type:"Full-Time",
            jd:["We are seeking a talented Marketing and Sales Specialist to join our startup team and drive growth through strategic marketing initiatives and effective sales strategies. As a small team of energetic individuals, you will have the opportunity to directly work with the core team and contribute significantly to our mission."],
            responsibilities: [
                "Develop and execute comprehensive marketing strategies to drive brand awareness, generate leads, and acquire new customers.",
                "Collaborate with cross-functional teams to develop marketing collateral, including website content, sales materials, email campaigns, and social media content.",
                "Conduct market research and competitor analysis to identify opportunities for differentiation and market positioning.",
                "Manage and optimize digital marketing channels, including SEO, SEM, social media, email marketing, and content marketing, to drive customer engagement and conversion.",
                "Analyze marketing metrics and KPIs to measure the effectiveness of campaigns and identify areas for optimization.",
                "Build and nurture relationships with prospective customers through targeted outreach, personalized communication, and relationship-building activities.",
                "Lead the sales process from lead generation to close, including conducting product demonstrations, negotiating contracts, and closing deals.",
                "Provide ongoing support and guidance to customers throughout the sales cycle to ensure a seamless experience and drive customer satisfaction and retention.",
            ],
            qualifications: [
                "Bachelor's degree in Marketing, Business Administration, or related field.",
                "Proven experience in marketing and sales, with a focus on SaaS and digital marketing.",
                "Strong understanding of digital marketing principles and best practices, including SEO, SEM, social media, email marketing, and content marketing.",
                "Excellent communication and interpersonal skills, with the ability to effectively convey complex concepts to diverse audiences.",
                "Demonstrated ability to develop and execute strategic marketing plans and sales strategies to achieve business objectives.",
                "Analytical mindset with the ability to analyze data, draw insights, and make data-driven decisions.",
                "Self-motivated and results-oriented, with a strong sense of ownership and accountability.",
                "Experience working in a fast-paced, dynamic environment with the ability to prioritize and manage multiple projects simultaneously.",
                "Submission of a Resume, Cover Letter, and a portfolio showcasing at least 3 different previous works demonstrating expertise in SaaS and digital marketing.",

            ],
            benefits: [
                "Competitive salary and performance-based incentives with the opportunity for additional rewards based on performance.",
                "Comprehensive health insurance plans.",
                "Professional development opportunities.",
                "Fun and collaborative work environment with a small team of energetic individuals.",
                "Opportunity for career growth and advancement within a rapidly growing startup company.",
            ],
        }
    
]