import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import NavLight from "../components/navlight";
import Switcher from "../components/switcher";
import Footer from "../components/footer";
import Select from "react-select";

export default function Careers() {
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);

  const Navigate = useNavigate();

    const jobData = [
        {
            id: 1,
            name:"Front-End Developer",
            field: "IT",
            place: "Bangalore, India",
            type:"Full-Time",
        },
        
        {
            id: 2,
            name:"Marketing and Sales Specialist",
            field: "Marketing",
            place: "Bangalore, India",
            type:"Full-Time",
        },
        // {
        //     id: 3,
        //     name:"Back-End Developer",
        //     field: "IT",
        //     place: "Bangalore, India",
        //     type:"Intern",
        // },
    ]
    // console.log("filter", filteredData)

    const handleSearch = () =>{
      const lowercasedQuery = searchQuery.toLowerCase();
      const filtered = jobData?.filter((item) =>
      Object.values(item).some((val) => {
        if (typeof val === "string") {
          return val.toLowerCase().includes(lowercasedQuery);
        } else if (typeof val === "number") {
          return val.toString().includes(lowercasedQuery);
        }
      })
    );
    setFilteredData(filtered);
    }

    const displayJobs = filteredData.length > 0? filteredData:jobData

  return (
    <>
      <NavLight />
      <section className="relative md:py-24 py-32 bg-[url('../../assets/images/bg/bg-pages.jpg')] bg-no-repeat bg-cover">
        <div className="absolute inset-0 bg-gradient-to-t from-slate-900 to-slate-900/70"></div>
        <div className="container relative">
          <div className="grid grid-cols-1 text-center mt-6">
            <div>
              <h5 className="md:text-4xl text-3xl md:leading-normal leading-normal tracking-wider font-semibold text-white mb-0">
                Careers
              </h5>
            </div>

            <ul className="tracking-[0.5px] mb-0 inline-block mt-5">
              <li className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white/50 hover:text-white">
                <Link to="/">Home</Link>
              </li>
              <li className="inline-block text-base text-white/50 mx-0.5 ltr:rotate-0 rtl:rotate-180">
                <i className="mdi mdi-chevron-right"></i>
              </li>
              <li
                className="inline-block capitalize text-[15px] font-medium duration-500 ease-in-out text-white"
                aria-current="page"
              >
                Careers
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className="relative">
        <div className="shape absolute sm:-bottom-px -bottom-[2px] start-0 end-0 overflow-hidden z-1 text-white dark:text-slate-900">
          <svg
            className="w-full h-auto scale-[2.0] origin-top"
            viewBox="0 0 2880 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z"
              fill="currentColor"
            ></path>
          </svg>
        </div>
      </div>

      <section className="relative md:py-24 py-16 mt-20">
        <div className="container relative">
            <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-6">
            <input
                className="block w-full rounded-md border-10 py-1.5 pl-7 pr-20 placeholder:text-gray-400"
                style={{color:"black", borderWidth:"1px"}}
                type="text"
                placeholder="Type keyword or skills"
                onChange={(e) => setSearchQuery(e.target.value)}
                // value={searchQuery}
                />
            <Select className="ms-1 text-black-400" placeholder="Select location" options={[{
              value: "India",
              label: "India"
            }]}/>
            <button className="block w-10 rounded-md border-10 py-1.5 pl-7 pr-20 placeholder:text-gray-400 hover:text-amber-400" style={{borderWidth:"1px"}} onClick={handleSearch}>Search</button>
            </div>
            <div className="">
            <h3 className="md:text-2xl text-3xl md:leading-normal leading-normal tracking-wider text-center mt-20">
                Current Openings
            </h3>
            <div className="mt-20">
            
                {
                    displayJobs.map((item, index)=>{
                        return(
                            <>
                            <div className="relative bg-white dark:bg-slate-900 p-4 rounded-md shadow dark:shadow-gray-700 mt-5" key={index}>
                            <div className="grid md:grid-cols-6 grid-cols-2 text-center">
                            <label>
                {item.id}.
                </label>
                                 
                                 <label>
                {item.name}
                </label>
                <label>
                {item.field}
                </label>
                
                <label>
                {item.place}
                </label>
                
                <label>
                {item.type}
                </label>
                
                <button className="hover:text-amber-400" onClick={()=>{Navigate(`/career-detail/${item.id}`)}}>View & Apply</button>
                                 </div>
                                 </div>
                            </>
                        )
                    })
                }
                
                </div>
            </div>
            </div>
            



      </section>

      <Switcher />
      <Footer />
    </>
  );
}
