import React from "react";
import { Link, Navigate } from "react-router-dom";
import LogoLight from '../assets/images/demetrix_logo-white.png'

import {FiShoppingCart, FiDribbble, FiLinkedin, FiFacebook, FiInstagram, FiTwitter, FiMail,FiFileText, FiPhone, FiMapPin} from '../assets/icons/vander'

export default function Footer(){
    return(
        <>
         <div className="container relative">
            <div className="shape absolute xl:-bottom-[30px] lg:-bottom-[16px] md:-bottom-[13px] -bottom-[5px] start-0 end-0 overflow-hidden z-1 rotate-180 text-white dark:text-slate-900">
                <svg className="w-full h-auto scale-[2.0] origin-top" viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
                </svg>
            </div>
        </div>
        <footer className="relative bg-gray-900 overflow-hidden">
            <span className="absolute blur-[200px] w-[300px] h-[300px] rounded-full top-0 -start-[0] bg-gradient-to-tl to-amber-400  from-fuchsia-600 z-0"></span>
            {/* <div className="container-fluid relative md:py-24 py-16">
                <div className="container relative">
                    <div className="grid grid-cols-1 text-center">
                        <div className="">
                            <h4 className="font-bold lg:leading-normal leading-normal text-4xl lg:text-5xl text-white tracking-normal mb-4">Start Your Free Trail.</h4>
                            <p className="text-white/70 text-lg max-w-xl mx-auto">Artificial intelligence makes it fast easy to create content for your blog, social media, website, and more!</p>

                            <div className="mt-6">
                                <Link to="" className="py-2 px-5 inline-block font-semibold tracking-wide border align-middle duration-500 text-base text-center bg-transparent hover:bg-amber-400 border-gray-800 dark:border-slate-800 hover:border-amber-400 dark:hover:border-amber-400 text-white rounded-md">Join Now!</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}

            <div className="container relative">
                <div className="grid grid-cols-1 border-t border-gray-800 dark:border-slate-800">
                    <div className="py-[30px] px-0">
                        <div className="grid md:grid-cols-3">
                            <div className="text-center">
                                <Link to="#" className="text-[22px] focus:outline-none">
                                    {/* <img src={LogoLight} className="h-12" alt=""/> */}
                                    <h5 className="content title h5 text-lg font-semibold mt-3" style={{color:"white"}}>Demetrix Infotech Private Limited</h5>
                                </Link>
                            <p className="text-slate-400 mt-3">#401, 4th Floor, HVS Court,<br/> Cunningham Road, Bangalore, <br/> Karnataka - 560052</p>
                            <ul className="list-none footer-list mt-6 md:mt-0 space-x-1"> 
                                {/* <li className="inline"><Link to="https://1.envato.market/mortalai-react" target="_blank" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 dark:border-slate-800 rounded-md hover:border-amber-400 dark:hover:border-amber-400 hover:bg-amber-400 dark:hover:bg-amber-400 text-slate-300 hover:text-white"><FiShoppingCart className="h-4 w-4 align-middle"/></Link></li> */}
                                {/* <li className="inline"><Link to="https://dribbble.com/shreethemes" target="_blank" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 dark:border-slate-800 rounded-md hover:border-amber-400 dark:hover:border-amber-400 hover:bg-amber-400 dark:hover:bg-amber-400 text-slate-300 hover:text-white"><FiDribbble className="h-4 w-4 align-middle"/></Link></li> */}
                                <li className="inline"><Link to="http://linkedin.com/company/100504970" target="_blank" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 dark:border-slate-800 rounded-md hover:border-amber-400 dark:hover:border-amber-400 hover:bg-amber-400 dark:hover:bg-amber-400 text-slate-300 hover:text-white"><FiLinkedin className="h-4 w-4 align-middle"/></Link></li>
                                <li className="inline"><Link to="https://www.facebook.com/profile.php?id=61556299958465" target="_blank" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 dark:border-slate-800 rounded-md hover:border-amber-400 dark:hover:border-amber-400 hover:bg-amber-400 dark:hover:bg-amber-400 text-slate-300 hover:text-white"><FiFacebook className="h-4 w-4 align-middle"/></Link></li>
                                <li className="inline"><Link to="https://www.instagram.com/demetrix_in/" target="_blank" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 dark:border-slate-800 rounded-md hover:border-amber-400 dark:hover:border-amber-400 hover:bg-amber-400 dark:hover:bg-amber-400 text-slate-300 hover:text-white"><FiInstagram className="h-4 w-4 align-middle"/></Link></li>
                                {/* <li className="inline"><Link to="https://twitter.com/shreethemes" target="_blank" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 dark:border-slate-800 rounded-md hover:border-amber-400 dark:hover:border-amber-400 hover:bg-amber-400 dark:hover:bg-amber-400 text-slate-300 hover:text-white"><FiTwitter className="h-4 w-4 align-middle"/></Link></li> */}
                                <li className="inline"><Link to="mailto:info@demetrix.in" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 dark:border-slate-800 rounded-md hover:border-amber-400 dark:hover:border-amber-400 hover:bg-amber-400 dark:hover:bg-amber-400 text-slate-300 hover:text-white"><FiMail className="h-4 w-4 align-middle"/></Link></li>
                                {/* <li className="inline"><Link to="https://forms.gle/QkTueCikDGqJnbky9" target="_blank" className="h-8 w-8 inline-flex items-center justify-center tracking-wide align-middle duration-500 text-base text-center border border-gray-800 dark:border-slate-800 rounded-md hover:border-amber-400 dark:hover:border-amber-400 hover:bg-amber-400 dark:hover:bg-amber-400 text-slate-300 hover:text-white"><FiFileText className="h-4 w-4 align-middle"/></Link></li> */}
                            </ul>
                            </div>

                            <div className="text-center">
                                <h5 className="title h5 text-lg font-semibold mt-3" style={{color:"white"}}>Products</h5>
                                {/* <Link to="/software-solutions">
                                <p className="text-slate-400 mt-3">Software Solutions</p>
                                </Link> */}
                                <div>
                                <Link to="/AI-software" onClick={()=>localStorage.setItem("productType",2)}>
                                <p className="text-slate-400 mt-3">Software Products</p>
                                </Link>
                                </div>
                                <div>
                                <Link to="/AI-hardware" onClick={()=>localStorage.setItem("productType",3)}>
                                <p className="text-slate-400 mt-3">Hardware Products</p>
                                </Link>
                                </div>
                            </div>
                            <div className="text-center">
                                <h5 className="title h5 text-lg font-semibold mt-3" style={{color:"white"}}>Terms</h5>
                                <Link to="/privacy">
                                <p className="text-slate-400 mt-3">Privacy policy</p>
                                </Link>
                                {/* <Link to="/privacy">
                                <p className="text-slate-400 mt-3">Terms of Service</p>
                                </Link> */}
                                
                            </div>
                        </div>
                        <div className="text-center">
                        &copy; 2024 Demetrix Infotech Private Limited.
                        </div>
                        
                    </div>
                </div>
            </div>

            {/* <div className="py-[30px] px-0 border-t border-gray-800 dark:border-slate-800">
                <div className="container relative text-center">
                    <div className="grid grid-cols-1">
                        <div className="text-center">
                            <p className="text-gray-400">© {new Date().getFullYear()} Mortal.Ai. Design with <i className="mdi mdi-heart text-orange-700"></i> by <Link to="https://shreethemes.in/" target="_blank" className="text-reset">Shreethemes</Link>.</p>
                        </div>
                    </div>
                </div>
            </div> */}
        </footer>
        </>
    )
}