import React from "react";
import about1 from "../assets/images/blog/2.jpg"
import about2 from "../assets/images/blog/3.jpg"
import about3 from "../assets/images/features/img3.jpg"

import { Link } from "react-router-dom";
import { FiCheckCircle, MdKeyboardArrowRight } from "../assets/icons/vander";

export default function Features({classlist}){
    const featuresData = [
        {
            image:about1,
            title:"Software Products.",
            desc:"Software Solutions and products",
            link:"/AI-software",
            productType: 2
        },
        {
            image:about2,
            title:"Hardware Products.",
            desc:"Hardware Solutions and products",
            link:"/AI-hardware",
            productType: 3
        },
        // {
        //     image:about3,
        //     title:"Software Solutions.",
        //     desc:"Artificial intelligence makes it fast easy to create content for your video creations.",
        //     link:"/software-solutions"
        // },
    ]
    return(
        <>
            <div className={classlist}>
                <div className="grid grid-cols-1 pb-6 text-center">
                    <h3 className="mb-4 md:text-3xl md:leading-normal text-2xl leading-normal font-semibold">Our Products & Solutions</h3>

                    <p className="text-slate-400 max-w-xl mx-auto">Keeping in line with the increase in use of technology Demetrix Infotech has developed several tools which includes software and hardware and are implemented across Asia and Europe.</p>
                </div>

                <div className="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-1 mt-6 gap-6">
                    {featuresData.map((item,index) => {
                        return(
                        <div className="relative overflow-hidden bg-white dark:bg-slate-900 rounded-md shadow dark:shadow-gray-800" key={index}>
                            <div className="p-6 pb-0 relative overflow-hidden after:content-[''] after:absolute after:inset-0 after:mx-auto after:w-72 after:h-72 after:bg-gradient-to-tl after:to-amber-400 after:from-fuchsia-600 after:blur-[80px] after:rounded-full">
                                <img src={item.image} className="relative rounded-t-md shadow-md dark:shadow-slate-700 z-1" alt=""/>
                            </div>

                            <div className="p-6">
                                <h5 className="text-lg font-semibold">{item.title}</h5>
                                <p className="text-slate-400 mt-3">{item.desc}</p>
                                <div className="">
              <Link
                to={item.link}
                className="hover:text-amber-400 font-medium duration-500 inline-flex items-center"
                onClick={()=>localStorage.setItem("productType",item.productType)}
              >
                Find Out More{" "}
                <MdKeyboardArrowRight className="ms-1 text-[20px]" />
              </Link>
            </div>
                            </div>
                           
                        </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}